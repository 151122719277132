<template>
  <div>
    <Breadcrumb :nameNav="nameNav"></Breadcrumb>
    <!-- Table Container Card -->

    <b-card class="m-2" no-body>
      <b-card-header class="pb-50">
        <h5 class="text-capitalize">
          <span v-if="!isUpdating">{{ $t("alarm_views.action_add") }}</span>
          <span v-else>{{ $t("alarm_views.action_update") }}</span>
        </h5>
      </b-card-header>
      <b-card-body>
        <div class="mx-2 mb-2">
          <validation-observer ref="refFormObserver">
            <div class="m-2">
              <!-- ===================================== -->
              <b-row class="mb-2">
                <b-col md="12" class="mb-2">
                  <h3>{{ $t("alarm_views.subtitle_1") }}</h3>
                </b-col>

                <b-col class="mb-1 mb-md-0">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('alarm_views.fields.name.label')"
                    rules="required"
                  >
                    <label class="d-block">{{
                      $t("alarm_views.fields.name.label")
                    }}</label>
                    <b-form-input
                      v-model="viewName"
                      class="d-inline-block mr-1"
                      :placeholder="$t('alarm_views.fields.name.placeholder')"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>

                <b-col class="mb-1 mb-md-0">
                  <validation-provider
                    #default="validationContext"
                    name="Tenant"
                    rules="required"
                  >
                    <label class="d-block" for="tenant">
                      {{ $t("alarm_views.fields.tenant.label") }}
                    </label>
                    <v-select
                      v-model="selectedTenant"
                      :options="tenantOptions"
                      :clearable="false"
                      input-id="tenant"
                      class="w-100"
                      :reduce="(val) => val.value"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>

                <b-col class="mb-1 mb-md-0">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('alarm_views.fields.users.label')"
                    rules="required"
                  >
                    <label class="d-block" for="users">
                      {{ $t("alarm_views.fields.users.label") }}
                    </label>
                    <v-select
                      v-model="selectedUsers"
                      :options="usersOptions"
                      class="w-100"
                      input-id="users"
                      multiple
                      :reduce="(val) => val.value"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
              </b-row>

              <!-- ===================================== -->
              <b-row class="mb-2">
                <b-col class="mb-1 mb-md-0">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('alarm_views.fields.alarm_level.label')"
                    rules="required"
                  >
                    <label class="d-block" for="alarm_level">
                      {{ $t("alarm_views.fields.alarm_level.label") }}
                    </label>
                    <v-select
                      v-model="selectedAlarmLevel"
                      :options="alarmLevelOptions"
                      class="w-100"
                      input-id="alarm_level"
                      multiple
                      :reduce="(val) => val.value"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>

                <b-col class="mb-1 mb-md-0">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('alarm_views.fields.alarm_type.label')"
                    rules="required"
                  >
                    <label class="d-block" for="alarm_type">
                      {{ $t("alarm_views.fields.alarm_type.label") }}
                    </label>
                    <v-select
                      v-model="selectedAlarmType"
                      :options="alarmTypeOptions"
                      class="w-100"
                      input-id="alarm_type"
                      multiple
                      :reduce="(val) => val.value"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>

                <b-col class="mb-1 mb-md-0">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('alarm_views.fields.alarm_status.label')"
                    rules="required"
                  >
                    <label class="d-block" for="alarm_status">
                      {{ $t("alarm_views.fields.alarm_status.label") }}
                    </label>
                    <v-select
                      v-model="selectedAlarmStatus"
                      :options="alarmStatusOptions"
                      class="w-100"
                      input-id="alarm_status"
                      multiple
                      :reduce="(val) => val.value"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
              </b-row>

              <!-- ===================================== -->
              <b-row class="mb-2">
                <b-col md="4">
                  <div>
                    <b-form-checkbox
                      id="is_default"
                      name="is_default"
                      v-model="isDefault"
                      class="text-capitalize py-50"
                      switch
                    >
                      {{ $t("alarm_views.fields.default_view.label") }}
                    </b-form-checkbox>
                  </div>
                </b-col>

                <!-- =================== Frequency ================== -->
                <b-col md="4" class="mb-1 mb-md-0">
                  <label class="d-block">
                    {{ $t("alarm_views.fields.periodicity.label") }}
                  </label>
                  <div>
                    <b-form-checkbox
                      id="always"
                      name="always"
                      v-model="always"
                      class="text-capitalize py-50"
                      switch
                    >
                      {{ $t("alarm_views.fields.periodicity.always") }}
                    </b-form-checkbox>
                  </div>
                  <b-tabs content-class="w-75" v-if="!always">
                    <b-tab
                      v-for="(weekday, index) in weekdays"
                      :key="index"
                      :title="$t(`alarm_views.fields.periodicity.days.${weekday.text}`)"
                    >
                      <table class="table table-sm w-75 mb-2">
                        <tr>
                          <th>{{ $t("alarm_views.fields.periodicity.from") }}</th>
                          <th>{{ $t("alarm_views.fields.periodicity.to") }}</th>
                          <th></th>
                        </tr>
                        <tr v-for="(dt, dt_index) in weekday.data" :key="dt_index">
                          <td>
                            <input
                              type="time"
                              v-model="weekday.data[dt_index].start_at"
                            />
                          </td>
                          <td>
                            <input type="time" v-model="weekday.data[dt_index].end_at" />
                          </td>
                          <td>
                            <b-button
                              variant="danger"
                              @click="removeDt(index, dt_index)"
                              size="sm"
                            >
                              {{ $t("alarm_views.fields.periodicity.btns.delete") }}
                            </b-button>
                          </td>
                        </tr>
                      </table>

                      <div class="d-flex justify-content-end">
                        <b-button variant="success" @click="addDt(index)" size="sm">
                          {{ $t("alarm_views.fields.periodicity.btns.add") }}
                        </b-button>
                      </div>
                    </b-tab>

                    <p class="text-danger" v-if="showWeekdaysValidationErrors">
                      {{ $t("alarm_views.fields.periodicity.messages.invalid_schedule") }}
                    </p>
                  </b-tabs>
                </b-col>
              </b-row>

              <!-- ===================================== -->
              <b-row>
                <b-col class="mb-2" md="12">
                  <hr class="mb-1" />
                  <h3>{{ $t("alarm_views.subtitle_2") }}</h3>
                </b-col>

                <b-col md="12">
                  <b-row>
                    <b-col md="4">
                      <!-- ============== Layouts selector ============== -->

                      <slot-options class="mb-2" :tags-options="tagsOptions" />

                      <layouts-widget
                        parent="alarm-views"
                        :selected-layout="selectedLayout"
                        @layouts-widget-ready="onLayoutsWidgetReady"
                        @layout-selected="onLayoutSelected"
                        :force-wide-layout="true"
                        :collapsible="false"
                      />
                    </b-col>

                    <!-- ============== Grid ============== -->
                    <b-col md="8">
                      <p class="text-danger" v-if="showLayoutItemsValidationErrors">
                        El layout no puede estar completamente vacío
                      </p>
                      <preview-layout
                        v-if="selectedLayout"
                        :selected-layout="selectedLayout"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </validation-observer>
          <b-row>
            <b-col md="12" class="d-flex justify-content-end">
              <b-button variant="success" @click="save">
                <span v-if="!isUpdating">{{ $t("alarm_views.btns.create") }}</span>
                <span v-else>{{ $t("alarm_views.btns.update") }}</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import useAlarmViewConfig from "./useAlarmViewConfig";
import LayoutsWidget from "../components/GridView/widgets/LayoutsWidget.vue";
import PreviewLayout from "./layout/PreviewLayout.vue";
import SlotOptions from "./widgets/SlotOptions.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  components: {
    Breadcrumb,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormCheckbox,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    LayoutsWidget,
    PreviewLayout,
    SlotOptions,
  },
  setup(_) {
    return { ...useAlarmViewConfig() };
  },
};
</script>


