<template>
  <b-list-group>
    <b-list-group-item href="#">
      <p class="m-0" v-b-toggle="'av-accordion-1'">Tags</p>
      <b-collapse id="av-accordion-1" role="tabpanel" class="p-25">
        <ul class="list-unstyled p-1">
          <li v-for="tag in tagsOptions" :key="tag.id">
            <a
              href="#"
              @click.prevent="
                onClickDataType(SLOT_TYPES.TAG, {
                  id: `${tag.label}`,
                  label: `${tag.label} (${$t('live')})`,
                })
              "
            >
              - {{ tag.label }} ({{ $t("live") }})
            </a>
            <br />
            <a
              href="#"
              @click.prevent="
                onClickDataType(SLOT_TYPES.TAG, {
                  id: `${tag.label}:PA`,
                  label: `${tag.label} (${$t('pre_alarm')})`,
                })
              "
            >
              - {{ tag.label }} ({{ $t("pre_alarm") }})
            </a>
            <hr />
          </li>
        </ul>
      </b-collapse>
    </b-list-group-item>

    <b-list-group-item href="#" @click.prevent="onClickDataType(SLOT_TYPES.INFO)">
      {{ $t(`slot_types.${SLOT_TYPES.INFO}`) }}
    </b-list-group-item>

    <b-list-group-item href="#" @click.prevent="onClickDataType(SLOT_TYPES.MAP)">
      {{ $t(`slot_types.${SLOT_TYPES.MAP}`) }}
    </b-list-group-item>

    <b-list-group-item href="#" @click.prevent="onClickDataType(SLOT_TYPES.UNITS_MAP)">
      {{ $t(`slot_types.${SLOT_TYPES.UNITS_MAP}`) }}
    </b-list-group-item>

    <b-list-group-item href="#" @click.prevent="onClickDataType(SLOT_TYPES.GPS)">
      {{ $t(`slot_types.${SLOT_TYPES.GPS}`) }}
    </b-list-group-item>

    <b-list-group-item
      href="#"
      @click.prevent="onClickDataType(SLOT_TYPES.TIMELINE)"
    >
      {{ $t(`slot_types.${SLOT_TYPES.TIMELINE}`) }}
    </b-list-group-item>

    <b-list-group-item
      href="#"
      @click.prevent="onClickDataType(SLOT_TYPES.DETECTION_EVENT)"
    >
      {{ $t(`slot_types.${SLOT_TYPES.DETECTION_EVENT}`) }}
    </b-list-group-item>

    <b-list-group-item href="#" @click.prevent="onClickDataType(SLOT_TYPES.ACTION_PLAN)">
      {{ $t(`slot_types.${SLOT_TYPES.ACTION_PLAN}`) }}
    </b-list-group-item>

    <b-list-group-item href="#" @click.prevent="onClickDataType(SLOT_TYPES.PLANE)">
      {{ $t(`slot_types.${SLOT_TYPES.PLANE}`) }}
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { BListGroup, BListGroupItem, BCollapse, VBToggle } from "bootstrap-vue";
import { SLOT_TYPES, SOURCES } from "@/config/layoutConfig";
import store from "@/store";

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BCollapse,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: ["tagsOptions"],
  data() {
    return {
      SLOT_TYPES,
    };
  },
  methods: {
    setSlotData(type, name, data = {}) {
      store.dispatch("alarm_views/setSlotData", {
        type,
        name,
        data,
        source: SOURCES.ALARM_VIEW,
      });
    },
    onClickDataType(type, _data = {}) {
      let title = "";
      let data = {};

      switch (type) {
        case SLOT_TYPES.CAM_LIVE:
          title = this.$t(`slot_types.${SLOT_TYPES.CAM_LIVE}`);
          break;

        case SLOT_TYPES.CAM_PA:
          title = this.$t(`slot_types.${SLOT_TYPES.CAM_PA}`);
          break;

        case SLOT_TYPES.INFO:
          title = this.$t(`slot_types.${SLOT_TYPES.INFO}`);
          break;

        case SLOT_TYPES.MAP:
          title = this.$t(`slot_types.${SLOT_TYPES.MAP}`);
          break;

        case SLOT_TYPES.UNITS_MAP:
          title = this.$t(`slot_types.${SLOT_TYPES.UNITS_MAP}`);
          break;

        case SLOT_TYPES.PLANE:
          title = this.$t(`slot_types.${SLOT_TYPES.PLANE}`);
          break;

        case SLOT_TYPES.ACTION_PLAN:
          title = this.$t(`slot_types.${SLOT_TYPES.ACTION_PLAN}`);
          break;

        case SLOT_TYPES.TAG:
          title = `${this.$t(`slot_types.${SLOT_TYPES.TAG}`)}: ${_data.label}`;
          data = { tag: _data.id };
          break;

        case SLOT_TYPES.GPS:
          title = this.$t(`slot_types.${SLOT_TYPES.GPS}`);
          break;

        case SLOT_TYPES.TIMELINE:
          title = this.$t(`slot_types.${SLOT_TYPES.TIMELINE}`);
        break;

        case SLOT_TYPES.DETECTION_EVENT:
          title = this.$t(`slot_types.${SLOT_TYPES.DETECTION_EVENT}`);
          break;
      }

      this.setSlotData(type, title, data);
    },
  },
};
</script>

<style lang="scss" scoped>
.cameras-container {
  margin-top: 0.2rem;
  max-height: 300px;
  overflow: auto;
}
</style>
