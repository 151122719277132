<template>
  <div class="grid-layout__container">
    <preview-slot
      v-for="(widget, index) in slots"
      :key="index"
      :index="index"
      :widget="widget"
      :slots="slots"
      :style="{ ...gridItemStyles(widget) }"
    />
  </div>
</template>

<script>
import { onMounted, reactive, computed, toRefs, watch } from "@vue/composition-api";
import store from "@/store";
import useLayout from "@/composables/layouts";
import PreviewSlot from "./PreviewSlot";

export default {
  components: { PreviewSlot },
  props: {
    selectedLayout: {
      required: true,
      type: String | Number,
    },
  },
  setup(props) {
    const state = reactive({ slots: [] });
    const layout = computed(() => {
      const userLayouts = store.getters["layouts/getUserLayouts"];
      return userLayouts.find((l) => l.id === props.selectedLayout);
    });
    const { gridItemStyles } = useLayout(props.selectedLayout, 55);

    const setGrid = () => {
      const grid = JSON.parse(JSON.stringify(layout.value.grid));
      state.slots = grid;
    };

    watch(
      () => props.selectedLayout,
      () => setGrid()
    );

    onMounted(async () => {
      await store.dispatch("layouts/getUserLayouts");
      setGrid();
    });

    return {
      gridItemStyles,
      ...toRefs(state),
      layout,
    };
  },
};
</script>

<style lang="scss" scoped>
.grid-layout__container {
  min-height: 500px;
}
</style>
