<template>
  <div :class="['layout-container']">
    <p class="text-center">{{ layout.name }}</p>
    <div class="grid-layout__container">
      <widget
        v-for="widget in items"
        :widget="widget"
        :key="widget.local_id"
        :style="{ ...gridItemStyles(widget) }"
      />
    </div>
  </div>
</template>

<script>
import Widget from "./LayoutPreviewSlot.vue";
import useLayout from "@/composables/layouts";
import { onMounted, reactive, toRefs } from "@vue/composition-api";

export default {
  components: {
    Widget,
  },
  props: {
    layout: Object,
  },
  setup(props) {
    const { gridItemStyles } = useLayout(props.layout, 15);
    const state = reactive({
      items: [],
    });

    onMounted(() => (state.items = props.layout.grid ? [...props.layout.grid] : [...props.layout.data]));

    return {
      ...toRefs(state),
      gridItemStyles,
    };
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  min-height: 170px;
  &.selected,
  &:hover {
    .app-grid {
      .slot-item {
        border: 1px solid #fff;
      }
    }
  }
}
</style>
