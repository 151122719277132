import { onMounted, reactive, nextTick, toRefs, toRef, ref } from "@vue/composition-api";

export default function useLayout(layout, _slotHeight = 15) {

    const slotHeight = ref(_slotHeight);

    const calcY = (item) => {
        return `${item.y * slotHeight.value}px`;
    };

    const calcHeight = (item) => {
        return `${item.h * slotHeight.value}px`;
    };

    const gridItemStyles = (item) => {
        return {
            top: calcY(item),
            height: calcHeight(item),
        }
    }

    return { slotHeight, gridItemStyles };
}
