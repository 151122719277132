var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'layouts bg-white border-bottom',
    { widget: !['alarm-views'].includes(_vm.parent) },
    { 'border-bottom': !['alarm-views'].includes(_vm.parent) } ],staticStyle:{"position":"relative"}},[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('div',{staticClass:"widget-header border-bottom",style:({ display: _vm.parent !== 'alarm-views' ? 'block' : 'none' })},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(_vm.collapsible ? ("main-accordion-" + _vm.id) : ''),expression:"collapsible ? `main-accordion-${id}` : ''"}],staticClass:"m-0 h4 d-flex"},[_c('p',{staticClass:"mb-0 mx-auto text-center"},[_vm._v(_vm._s(_vm.title))]),(_vm.collapsible)?[(!_vm.isConfigModeOn)?_c('feather-icon',{style:({ opacity: !_vm.searchBarEnabled ? 1 : 0 }),attrs:{"icon":_vm.isCollapseOpen ? 'ChevronUpIcon' : 'ChevronDownIcon',"size":"24"}}):_vm._e()]:_vm._e(),(_vm.isConfigModeOn)?_c('span',{staticClass:"handle cursor-move",style:({ opacity: !_vm.searchBarEnabled ? 1 : 0 })},[_c('feather-icon',{attrs:{"icon":"MenuIcon","size":"24"}})],1):_vm._e()],2),_c('search-bar-widgets',{attrs:{"search-bar-width":_vm.searchBarWidth,"sidebar-min-width-reached":_vm.sidebarMinWidthReached},on:{"input":_vm.onSearch,"toggle-search-bar":_vm.toggleSearchBar,"reset-search-bar":_vm.resetSearchBar}})],1),_c('b-collapse',{attrs:{"id":("main-accordion-" + _vm.id),"accordion":("main-accordion-" + _vm.id),"role":"tabpanel","visible":false},on:{"input":_vm.onUpdateStatus},model:{value:(_vm.isCollapseOpen),callback:function ($$v) {_vm.isCollapseOpen=$$v},expression:"isCollapseOpen"}},[_c('div',{class:[
          'layouts-container d-flex flex-wrap justify-content-between',
          { 'flex-column': _vm.sidebarMinWidthReached } ]},_vm._l((_vm.items),function(layout,index){return _c('div',{key:index,class:[
            'layout',
            {
              selected: _vm.checkSelectedLayout(layout.id),
              'w-100': _vm.sidebarMinWidthReached,
              'w-50': !_vm.sidebarMinWidthReached,
            } ],on:{"click":function($event){return _vm.setLayout(layout)}}},[_c('layout-preview',{key:layout.id,attrs:{"layout":layout}})],1)}),0),_c('pagination-widgets',{attrs:{"sidebar-min-width-reached":_vm.sidebarMinWidthReached,"per-page":_vm.perPage,"current-page":_vm.currentPage,"total-records":_vm.sourceData.length},on:{"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event},"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }