<template>
  <div
    :class="[
      'layouts bg-white border-bottom',
      { widget: !['alarm-views'].includes(parent) },
      { 'border-bottom': !['alarm-views'].includes(parent) },
    ]"
    style="position: relative"
  >
    <div class="accordion" role="tablist">
      <div
        class="widget-header border-bottom"
        :style="{ display: parent !== 'alarm-views' ? 'block' : 'none' }"
      >
        <div class="m-0 h4 d-flex" v-b-toggle="collapsible ? `main-accordion-${id}` : ''">
          <p class="mb-0 mx-auto text-center">{{ title }}</p>
          <template v-if="collapsible">
            <feather-icon
              v-if="!isConfigModeOn"
              :icon="isCollapseOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              :style="{ opacity: !searchBarEnabled ? 1 : 0 }"
              size="24"
            />
          </template>
          <span
            class="handle cursor-move"
            v-if="isConfigModeOn"
            :style="{ opacity: !searchBarEnabled ? 1 : 0 }"
          >
            <feather-icon icon="MenuIcon" size="24" />
          </span>
        </div>
        <search-bar-widgets
          :search-bar-width="searchBarWidth"
          :sidebar-min-width-reached="sidebarMinWidthReached"
          @input="onSearch"
          @toggle-search-bar="toggleSearchBar"
          @reset-search-bar="resetSearchBar"
        />
      </div>
      <b-collapse
        :id="`main-accordion-${id}`"
        :accordion="`main-accordion-${id}`"
        role="tabpanel"
        :visible="false"
        v-model="isCollapseOpen"
        @input="onUpdateStatus"
      >
        <div
          :class="[
            'layouts-container d-flex flex-wrap justify-content-between',
            { 'flex-column': sidebarMinWidthReached },
          ]"
        >
          <div
            v-for="(layout, index) in items"
            :key="index"
            :class="[
              'layout',
              {
                selected: checkSelectedLayout(layout.id),
                'w-100': sidebarMinWidthReached,
                'w-50': !sidebarMinWidthReached,
              },
            ]"
            @click="setLayout(layout)"
          >
            <layout-preview :key="layout.id" :layout="layout" />
          </div>
        </div>
        <pagination-widgets
          :sidebar-min-width-reached="sidebarMinWidthReached"
          :per-page.sync="perPage"
          :current-page.sync="currentPage"
          :total-records="sourceData.length"
        />
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { BCollapse, VBToggle } from "bootstrap-vue";
import store from "@/store";
import LayoutPreview from "./LayoutPreview";
import PaginationWidgets from "./components/PaginationWidgets";
import SearchBarWidgets from "./components/SearchBarWidgets.vue";

export default {
  components: {
    BCollapse,
  },
  components: {
    LayoutPreview,
    BCollapse,
    PaginationWidgets,
    SearchBarWidgets,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    id: {
      type: Number | String,
      default: 1,
    },
    location: {
      type: String,
      default: "sideBar",
    },
    title: {
      type: String | Number,
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    parent: {
      type: String | Number,
      default: "sidebar",
    },
    selectedLayout: {
      type: String | Number,
      default: null,
    },
    forceWideLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCollapseOpen: true,
      perPage: 4,
      currentPage: 1,
      sourceData: [],
      searchBarEnabled: false,
      searchBarWidth: 0,
    };
  },
  mounted() {
    this.isCollapseOpen = this.isOpen;
    store.dispatch("layouts/getUserLayouts").then(() => {
      if (this.parent !== "sidebar") {
        this.$emit("layouts-widget-ready");
      }
      this.sourceData = this.userLayouts;
    });
    this.searchBarWidth = this.$el.offsetWidth - 20;
  },
  watch: {
    sidebarWidth() {
      this.searchBarWidth = this.$el.offsetWidth - 20;
    },
  },
  computed: {
    currentTab() {
      return store.getters["grid/currentTab"] ? store.getters["grid/currentTab"] : {};
    },
    tabs() {
      return store.state.grid.tabs;
    },
    tabsQty() {
      return Object.keys(this.tabs).length;
    },
    isConfigModeOn() {
      return store.state.psimConfig.configModeStatus;
    },
    userLayouts() {
      return store.state.layouts.userLayouts.map((l) => ({
        id: l.id,
        name: l.name,
        grid: l.data,
      }));
    },
    sidebarMinWidthReached() {
      if (this.forceWideLayout) return false;
      return store.getters["grid/sidebarMinWidthReached"];
    },
    sidebarWidth() {
      return store.state.grid.sidebarWidth;
    },
    items() {
      return this.sourceData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  methods: {
    onUpdateStatus(value) {
      this.$emit("widget-collapsed", {
        id: this.id,
        property: "isOpen",
        location: this.location,
        value,
      });
    },
    checkSelectedLayout(idLayout) {
      if (this.parent === "sidebar") {
        return this.currentTab.layout === idLayout;
      }
      return this.selectedLayout == idLayout;
    },
    setLayout(layout) {
      if (this.parent === "sidebar") {
        if (this.tabsQty === 0) {
          store.dispatch("grid/addNewTab");
          store.dispatch("grid/setActiveTab", Object.keys(this.tabs)[0]);
        }
        store.dispatch("grid/setLayout", layout.id);
      } else {
        this.$emit("layout-selected", layout.id);
      }
    },
    onSearch(value) {
      if (value.length < 3) return;

      const search = value.toLowerCase();

      this.sourceData = this.userLayouts.filter((layout) =>
        layout.name.toLowerCase().includes(search)
      );

      this.currentPage = 1;
    },
    toggleSearchBar(status) {
      this.searchBarEnabled = status;
    },
    resetSearchBar() {
      this.sourceData = this.userLayouts;
      this.currentPage = 1;
    },
  },
};
</script>