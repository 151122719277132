<template>
  <div class="d-flex justify-content-end align-content-center">
    <div class="d-flex align-items-center mr-1 pr-1 border-right">
      <span>{{ $t("grid.widgets.units.pagination.showing") }}</span>
      <select class="mx-50" @input="onChangeRecordesPerPage">
        <option v-for="rpp in recordsPerPage" :key="rpp" :value="rpp">
          {{ rpp }}
        </option>
      </select>
      <span>{{ $t("grid.widgets.units.pagination.records") }}</span>
    </div>

    <div
      class="pagination-count d-flex align-items-center mr-25"
      v-if="!sidebarMinWidthReached"
    >
      {{ $t("grid.widgets.units.pagination.showing") }} {{ perPage }}
      {{ $t("grid.widgets.units.pagination.of") }} {{ totalRecords }} ({{
        $t("grid.widgets.units.pagination.page")
      }}
      {{ currentPage }} {{ $t("grid.widgets.units.pagination.of") }} {{ totalPages }})
    </div>
    <div class="pagination d-flex justify-content-end">
      <a
        href="#"
        :class="[
          'btn btn-sm btn-secondary px-50 rounded-0',
          { disabled: currentPage === 1 },
        ]"
        @click.prevent="clickPaginationBtn('prev')"
      >
        <feather-icon icon="ChevronLeftIcon" size="16" />
      </a>
      <a
        href="#"
        :class="[
          'btn btn-sm btn-secondary px-50 rounded-0',
          { disabled: totalPages === currentPage },
        ]"
        @click.prevent="clickPaginationBtn('next')"
      >
        <feather-icon icon="ChevronRightIcon" size="16" />
      </a>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/composition-api";

export default {
  props: {
    sidebarMinWidthReached: {
      type: Boolean,
      default: false,
    },
    recordsPerPage: {
      type: Array,
      default: () => [4, 10, 25, 50, 100],
    },
    perPage: {
      type: Number|String,
      default: 4,
    },
    currentPage: {
      type: Number|String,
      default: 1,
    },
    totalRecords: {
      type: Number|String,
      required: true,
    },
  },
  emits: ["update:currentPage", "update:currentPage", "update:perPage"],
  setup(props, { emit }) {
    const pagination = ref({
      first: null,
      last: null,
      next: null,
      prev: null,
    });

    const totalPages = computed(() => Math.ceil(props.totalRecords / props.perPage));

    const clickPaginationBtn = (direction) => {
      if (direction === "prev") {
        if (props.currentPage - 1 > 0) {
          emit("update:currentPage", props.currentPage - 1);
        }
      } else {
        if (props.currentPage + 1 <= totalPages.value) {
          emit("update:currentPage", props.currentPage + 1);
        }
      }
    };

    const onChangeRecordesPerPage = (val) => {
      emit("update:perPage", val.target.value);
      emit("update:currentPage", 1);
    };

    return {
      totalPages,
      clickPaginationBtn,
      onChangeRecordesPerPage,
    };
  },
};
</script>

<style></style>
