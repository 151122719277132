<template>
  <div :class="`grid-layout__item gl-w-${widget.w} gl-x-${widget.x}`">
    <div class="grid-layout__item--content"></div>
  </div>
</template>
<script>
export default {
  props: {
    widget: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
<style lang="scss" scoped>
.grid-layout__item {
  border: none;
  padding: 2px;
}
</style>
