<template>
  <div :class="['search-bar', { expanded: searchBarEnabled }]">
    <div
      class="search-bar-container d-flex align-items-center"
      :style="`width: ${searchBarWidth}px;`"
    >
      <feather-icon
        icon="SearchIcon"
        class="mx-25 cursor-pointer"
        size="24"
        @click="toggleSearchBar"
      />
      <input
        type="text"
        ref="searchBarInput"
        v-model="searchQuery"
        class="form-control"
        :placeholder="$t('search')"
        @input="onTypeSearch"
      />
      <feather-icon
        icon="XIcon"
        class="mx-25 cursor-pointer"
        size="18"
        @click="toggleSearchBar"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { ref } from "@vue/composition-api";

export default {
  props: {
    sidebarMinWidthReached: {
      type: Boolean,
      default: false,
    },
    searchBarWidth: {
      type: Number,
      default: 0,
    },
  },
  emits: ["input", "toggle-search-bar", "reset-search-bar"],
  setup(props, { emit }) {
    const searchQuery = ref("");
    const searchBarEnabled = ref(false);
    const searchBarInput = ref(null);

    const toggleSearchBar = () => {
      if (!searchBarEnabled.value) {
        searchBarInput.value.focus();
        searchBarEnabled.value = true;
      } else {
        searchBarEnabled.value = false;
      }
      emit("toggle-search-bar", searchBarEnabled.value);
      resetSearchBar();
    };

    const onTypeSearch = debounce(function (event) {
      if (event.target.value === "") {
        resetSearchBar();
      }
      emit("input", event.target.value);
    }, 800);

    const resetSearchBar = () => {
      emit("reset-search-bar");
    };

    return {
      searchQuery,
      searchBarEnabled,
      searchBarInput,
      toggleSearchBar,
      onTypeSearch,
    };
  },
};
</script>

<style></style>
