import { useToast } from "vue-toastification/composition";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import axios from "@axios";
import {
  computed,
  reactive,
  onMounted,
  nextTick,
  ref,
  watch,
} from "@vue/composition-api";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default function useAlarmViewConfig() {

  const isUpdating = ref(false);
  const idView = ref(null);
  const viewName = ref("");
  const tenants = ref([]);
  const tenantOptions = ref([]);
  const selectedTenant = ref(null);

  const nameNav = ref("");

  const users = ref([]);
  const selectedUsers = ref([]);
  const usersOptions = ref([]);

  const isDefault = ref(false);
  const always = ref(false);

  const selectedAlarmStatus = ref([]);
  const selectedAlarmType = ref([]);
  const selectedAlarmLevel = ref([]);

  const alarmStatusOptions = ref([]);
  const alarmTypeOptions = ref([]);
  const alarmLevelOptions = ref([]);

  const selectedLayout = ref(null);

  const tagsOptions = ref([]);

  const user = store.getters["user/getUser"];
  const toast = useToast();
  const { refFormObserver, getValidationState } = formValidation();
  const { t } = useI18nUtils();
  const trans = {
    'alarm_views.messages.success_created': t('alarm_views.messages.success_created'),
    'alarm_views.messages.success_updated': t('alarm_views.messages.success_updated'),
  }

  if (router.currentRoute.params.id_view) {
    nameNav.value = "actions.edit"
  } else {
    nameNav.value = "actions.add"
  }

  const weekdays = reactive({
    0: { text: "sun", data: [] },
    1: { text: "mon", data: [] },
    2: { text: "tue", data: [] },
    3: { text: "wed", data: [] },
    4: { text: "thu", data: [] },
    5: { text: "fri", data: [] },
    6: { text: "sat", data: [] },
  });
  const showWeekdaysValidationErrors = ref(false);
  const showLayoutItemsValidationErrors = ref(false);

  // Computed properties ------------------------------------
  const userLayouts = computed(() => (store.state.layouts.userLayouts));
  const layoutItems = computed(() => store.state.alarm_views.layoutItems);

  // Watchers ------------------------------------
  watch(selectedTenant, async (idTenant) => {
    if (idTenant) {
      selectedUsers.value = [];
      await loadConfigOptions(idTenant);
    } else {
      usersOptions.value = [];
    }
  });

  // Methods ------------------------------------

  const addDt = (weekday) => {
    weekdays[weekday].data.push({
      weekday,
      start_at: null,
      end_at: null,
    });
  };

  const removeDt = (weekday, index) => {
    weekdays[weekday].data.splice(index, 1);
  };

  const onLayoutsWidgetReady = () => {
    if (!selectedLayout.value && userLayouts.value.length) {
      selectedLayout.value = userLayouts.value[0].id;
    }
  };

  const onLayoutSelected = (id) => {
    selectedLayout.value = id;
  };

  const loadInitData = async () => {
    const tenants = await axios.get("v1/tenant");
    tenantOptions.value = tenants.data.data.map((tenant) => ({
      value: tenant.id,
      label: tenant.name,
    }));
  };

  const loadConfigOptions = (idTenant) => {
    return axios
      .get(`v1/services/views/get-config-options/${idTenant}`)
      .then((resp) => {

        alarmStatusOptions.value = resp.data.data.alarm_status.map(d => ({ label: d.label, value: d.value }));
        alarmTypeOptions.value = resp.data.data.alarm_types.map(d => ({ label: d.label, value: d.value }));
        alarmLevelOptions.value = resp.data.data.alarm_levels.map(d => ({ label: d.label, value: d.value }));
        usersOptions.value = resp.data.data.users.map(d => ({ label: d.label, value: d.value }));
        tagsOptions.value = resp.data.data.tags.map(d => ({ ...d }));

      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadUpdateData = async (idView) => {

    const { data } = await axios.get(`v1/services/views/get-view/${idView}`);
    const response = data.data;

    viewName.value = response.name;
    isDefault.value = response.is_default === 1;
    always.value = response.always === 1;
    selectedTenant.value = response.id_tenant;

    selectedAlarmStatus.value = response.alarm_status;
    selectedAlarmType.value = response.alarm_type;
    selectedAlarmLevel.value = response.alarm_level;
    selectedLayout.value = response.id_layout;
    selectedLayout.value = response.id_layout;
    response.datetimes.forEach(wkd => weekdays[wkd.weekday].data.push(wkd));

    nextTick(() => {
      selectedUsers.value = response.users;
      store.commit('alarm_views/setLayoutItems', response.layout_config);
    });
  };

  const validateDateTimes = () => {

    if (always.value) return true;

    let valid = false;

    for (const key in weekdays) {
      const wkday = weekdays[key];
      if (wkday.data.length > 0) {
        const invalid = wkday.data.filter(item => {
          const start = !item.start_at ? -1 : parseInt(item.start_at.replace(':', ''));
          const end = !item.end_at ? -1 : parseInt(item.end_at.replace(':', ''));

          return start === -1 || end === -1;
        });
        valid = invalid.length === 0;
      }
    }

    return valid;
  }

  const save = async () => {
    const validation = await refFormObserver.value.validate();

    const weekdaysValidation = validateDateTimes();
    const layoutItemsValidation = layoutItems.value.length > 0;

    showWeekdaysValidationErrors.value = !weekdaysValidation;
    showLayoutItemsValidationErrors.value = !layoutItemsValidation;

    if (validation && weekdaysValidation && layoutItemsValidation) {

      const weekdaysData = Object.values(weekdays)
        .filter(wkd => (wkd.data[0] ? { ...wkd.data[0] } : false))
        .map(wkd => (wkd.data.map(_wkd => ({ ..._wkd }))))
        .flat();

      const data = {
        name: viewName.value,
        id_tenant: selectedTenant.value,
        users: selectedUsers.value,
        alarm_status: selectedAlarmStatus.value,
        alarm_type: selectedAlarmType.value,
        alarm_level: selectedAlarmLevel.value,
        id_layout: selectedLayout.value,
        layout_config: layoutItems.value,
        always: always.value,
        is_default: isDefault.value,
        datetimes: weekdaysData
      };

      if (!isUpdating.value) {

        axios.post("v1/services/views", { ...data }).then(({ data }) => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans['alarm_views.messages.success_created'],
              icon: "CheckIcon",
              variant: "success",
            },
          });

          isUpdating.value = true;

          router.push({ path: `/alarm-views/config-view/${data.data.id}` });
        });

      } else {

        axios.put(`v1/services/views/${idView.value}`, { ...data }).then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans['alarm_views.messages.success_updated'],
              icon: "CheckIcon",
              variant: "success",
            },
          });
        });

      }

    }
  };

  // Hooks ------------------------------------
  onMounted(async () => {
    await loadInitData();

    if (router.currentRoute.params.id_view) {
      isUpdating.value = true;
      idView.value = router.currentRoute.params.id_view
      loadUpdateData(idView.value);
    } else {
      store.commit('alarm_views/setLayoutItems', []);
    }

  });

  return {
    isUpdating,
    viewName,

    tenants,
    tenantOptions,
    selectedTenant,
    selectedUsers,
    usersOptions,
    users,
    user,
    tagsOptions,

    isDefault,
    always,

    selectedAlarmStatus,
    selectedAlarmType,
    selectedAlarmLevel,

    alarmStatusOptions,
    alarmTypeOptions,
    alarmLevelOptions,

    selectedLayout,
    onLayoutsWidgetReady,
    onLayoutSelected,

    refFormObserver,
    getValidationState,
    required,

    weekdays,
    showWeekdaysValidationErrors,
    addDt,
    removeDt,
    showLayoutItemsValidationErrors,

    save,

    nameNav,
  };
}
